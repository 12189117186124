<template>
  <b-form-group
    label="Konu"
    label-for="title"
  >
    <validation-provider
      #default="{ errors }"
      name="Konu"
      rules="required"
    >
      <v-select
        v-model="dataItem.id_interview_subjects"
        :options="subjects"
        label="title"
        :reduce="item => item.id"
        placeholder="Seçiniz"
        @input="getStatuses"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'

export default {
  name: 'Country',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['interviews/dataItem']
    },
    subjects() {
      return this.$store.getters['interviewSubjects/dataList']
    },
  },
  created() {
    this.getData()
    localize('tr')
  },
  methods: {
    getData() {
      this.$store.dispatch('interviewSubjects/getDataList', {
        select: [
          'interview_subjects.id AS id',
          'interview_subjects.title AS title',
        ],
      })
    },
    getStatuses() {
      if (this.dataItem.id_interview_subjects) {
        this.dataItem.id_interview_statuses = null
        this.$store.dispatch('interviewStatuses/getDataList', {
          select: [
            'interview_statuses.id AS id',
            'interview_statuses.title AS title',
          ],
          where: {
            'interview_statuses.id_interview_subjects': this.dataItem.id_interview_subjects,
          },
        })
          .then(res => {
            if (res) {
              this.dataItem.id_interview_statuses = res[0].id
            }
          })
      }
    },
  },
}
</script>
