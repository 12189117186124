<template>
  <b-row v-if="interviewStatus !== '1'">
    <b-col cols="4">
      <b-card title="Görüşme Özeti">
        <interview-subject v-if="isFirst" />
        <interview-status :is-locked="isFirst" />
        <meets />
      </b-card>
    </b-col>
    <b-col cols="8">
      <b-card title="Görüşme Notu">
        <interview-content />
        <reminder-add />
      </b-card>
      <save-button
        :save-action="submitForm"
        :is-disabled="submitStatus"
      />
    </b-col>
  </b-row>
</template>
<script>
import { BRow, BCol, BCard } from 'bootstrap-vue'
import InterviewSubject from '@/views/Admin/Interviews/elements/InterviewSubject.vue'
import InterviewStatus from '@/views/Admin/Interviews/elements/InterviewStatus.vue'
import Meets from '@/views/Admin/Interviews/elements/Meets.vue'
import InterviewContent from '@/views/Admin/Interviews/elements/InterviewContent.vue'
import ReminderAdd from '@/views/Admin/Interviews/elements/ReminderAdd.vue'
import SaveButton from '@/layouts/components/common/SaveButton.vue'

export default {
  name: 'InterviewForm',
  components: {
    BRow,
    BCol,
    BCard,
    InterviewSubject,
    InterviewStatus,
    Meets,
    InterviewContent,
    ReminderAdd,
    SaveButton,
  },
  props: {
    isFirst: {
      type: Boolean,
      default: true,
    },
    interviewStatus: {
      type: String,
      required: true,
    },
    submitForm: {
      type: Function,
      required: true,
    },
    submitStatus: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
