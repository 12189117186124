<template>
  <b-form-group
    label="Dosya Yükle"
    label-for="image-upload"
  >
    <b-form-file
      id="file-upload"
      v-model="interviewFile.upload_file"
      placeholder="Bir dosya seçin..."
      drop-placeholder="Dosyayı buraya bırakın..."
      browse-text="Dosya Seçin"
    />
  </b-form-group>
</template>

<script>
import { BFormGroup, BFormFile } from 'bootstrap-vue'

export default {
  name: 'InterviewFile',
  components: {
    BFormGroup,
    BFormFile,
  },
  computed: {
    interviewFile() {
      return this.$store.getters['interviewFiles/dataItem']
    },
  },
}
</script>
