<template>
  <b-modal
    id="modal-center"
    v-model="modalShow"
    centered
    title="Görüşme Notu Güncelle"
    ok-only
    no-close-on-backdrop
    no-close-on-esc
    hide-header-close
    hide-footer
    size="lg"
  >
    <validation-observer ref="simpleRules">
      <meets />
      <interview-status :is-locked="!lastLine" />
      <interview-content />
      <div class="d-flex border-top pt-1 mt-1 justify-content-between">
        <b-button
          variant="danger"
          size="sm"
          @click="closeModal"
        >
          <FeatherIcon icon="XIcon" />
          İptal
        </b-button>
        <b-button
          variant="success"
          size="sm"
          @click="saveLine(interviewLine,lastLine)"
        >
          <FeatherIcon icon="SaveIcon" />
          Güncelle
        </b-button>
      </div>
    </validation-observer>
  </b-modal>
</template>
<script>
import { BModal, BButton } from 'bootstrap-vue'
import { ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import Meets from '@/views/Admin/Interviews/elements/ModalEditLine/Meets.vue'
import InterviewStatus from '@/views/Admin/Interviews/elements/ModalEditLine/InterviewStatus.vue'
import InterviewContent from '@/views/Admin/Interviews/elements/ModalEditLine/InterviewContent.vue'

export default {
  name: 'ModalEditLine',
  components: {
    BModal,
    BButton,
    Meets,
    InterviewStatus,
    InterviewContent,
    ValidationObserver,
  },
  props: {
    modalShow: {
      type: Boolean,
      default: false,
    },
    closeModal: {
      type: Function,
      required: true,
    },
    lastLine: {
      type: Boolean,
      default: false,
    },
    saveLine: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    interviewLine() {
      return this.$store.getters['interviews/dataLine']
    },
  },
  created() {
    localize('tr')
  },
}
</script>
