<template>
  <b-form-group
    label="Temas Tipi"
    label-for="title"
  >
    <validation-provider
      #default="{ errors }"
      name="Temas Tipi"
      rules="required"
    >
      <v-select
        v-model="dataItem.id_meets"
        :options="meets"
        label="title"
        :reduce="item => item.id"
        placeholder="Seçiniz"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'

export default {
  name: 'Country',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  props: {
    isLocked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['interviews/dataItem']
    },
    meets() {
      return this.$store.getters['meets/dataList']
    },
  },
  created() {
    this.getData()
    localize('tr')
  },
  methods: {
    getData() {
      this.$store.dispatch('meets/getDataList', {
        select: [
          'meets.id AS id',
          'meets.title AS title',
        ],
      })
    },
  },
}
</script>
