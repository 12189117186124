<template>
  <div>
    <b-form-checkbox
      v-model="reminder"
      name="check-button"
      switch
      inline
    >
      Hatırlatma Ekle
    </b-form-checkbox>
    <div
      v-if="reminder"
      class="mt-2"
    >
      <remind />
      <reminder-content />
    </div>
  </div>
</template>

<script>
import { BFormCheckbox } from 'bootstrap-vue'
import Remind from '@/views/Admin/Interviews/elements/Remind.vue'
import ReminderContent from '@/views/Admin/Interviews/elements/ReminderContent.vue'

export default {
  name: 'ReminderAdd',
  components: {
    BFormCheckbox,
    Remind,
    ReminderContent,
  },
  data() {
    return {
      reminder: false,
    }
  },
}
</script>
