<template>
  <b-modal
    id="modal-center"
    v-model="modalShow"
    centered
    title="Dosya Ekle"
    ok-only
    no-close-on-backdrop
    no-close-on-esc
    hide-header-close
    hide-footer
    size="lg"
  >
    <interview-file />
    <div class="d-flex border-top pt-1 mt-1 justify-content-between">
      <b-button
        variant="danger"
        size="sm"
        @click="closeModal"
      >
        <FeatherIcon icon="XIcon" />
        İptal
      </b-button>
      <b-button
        variant="success"
        size="sm"
        @click="uploadFile(interviewFile,lineId)"
      >
        <FeatherIcon icon="UploadIcon" />
        Yükle
      </b-button>
    </div>
  </b-modal>
</template>
<script>
import { BModal, BButton } from 'bootstrap-vue'
import InterviewFile from '@/views/Admin/Interviews/elements/ModalUploadLine/InterviewFile.vue'

export default {
  name: 'ModalEditLine',
  components: {
    BModal,
    BButton,
    InterviewFile,
  },
  props: {
    modalShow: {
      type: Boolean,
      default: false,
    },
    uploadFile: {
      type: Function,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
    lineId: {
      type: String,
      required: true,
    },
  },
  computed: {
    interviewFile() {
      return this.$store.getters['interviewFiles/dataItem']
    },
  },
}
</script>
