<template>
  <b-dropdown
    variant="link"
    toggle-class="p-0"
    no-caret
    dropleft
    :disabled="disabledStatus"
  >
    <template #button-content>
      <feather-icon
        icon="MoreVerticalIcon"
        size="16"
        class="align-middle text-body"
      />
    </template>
    <b-dropdown-item @click="editAction(dataId,lastLine)">
      <feather-icon icon="EditIcon" />
      <span class="align-middle ml-50">Güncelle</span>
    </b-dropdown-item>
    <b-dropdown-item @click="uploadAction(dataId)">
      <feather-icon icon="UploadIcon" />
      <span class="align-middle ml-50">Dosya Yükle</span>
    </b-dropdown-item>
    <b-dropdown-divider />
    <b-dropdown-item
      v-if="deleteAction && dataId"
      @click="deleteAction(dataId)"
    >
      <feather-icon icon="XIcon" />
      <span class="align-middle ml-50">Sil</span>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { BDropdown, BDropdownItem, BDropdownDivider } from 'bootstrap-vue'

export default {
  name: 'LineButtons',
  components: {
    BDropdown, BDropdownItem, BDropdownDivider,
  },
  props: {
    dataId: {
      type: String,
      required: false,
      default: null,
    },
    lastLine: {
      type: Boolean,
      default: false,
    },
    editAction: {
      type: Function,
      required: false,
      default: null,
    },
    uploadAction: {
      type: Function,
      required: false,
      default: null,
    },
    deleteAction: {
      type: Function,
      required: false,
      default: null,
    },
    disabledStatus: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
